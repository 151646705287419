import {
    handleQueryResolve,
    buildMapToField
} from '../utils';

export default function (patientID, rxNumber, genericName, brandName) {

    if (patientID) {

        let attrs = [{
            param: 'PatientID',
            type: 'sql.Int',
            value: patientID // to support like
        }];
        let where = ['Rx.PatID = @PatientID'];

        if (rxNumber) {
            attrs.push({
                param: 'rxNumber',
                type: 'sql.VarChar(50)',
                value: '%' + rxNumber + '%' // to support like
            });

            where.push('Rx.RxNum LIKE @rxNumber');
        }

        if (genericName) {
            attrs.push({
                param: 'genericName',
                type: 'sql.VarChar(50)',
                value: '%' + genericName + '%' // to support like
            });

            where.push('DrugRoot.GenericName LIKE @genericName');
        }

        if (brandName) {
            attrs.push({
                param: 'brandName',
                type: 'sql.VarChar(50)',
                value: '%' + brandName + '%' // to support like
            });

            where.push('DrugRoot.BrandName LIKE @brandName');
        }

        return this.query(`
        SELECT
        Rx.RxNum AS RxNumber,
        Rx.FillDate AS RxDate,
        Rx.DIN,
        DrugRoot.GenericName AS GenericName,
        DrugRoot.Strength AS RxStrength,
        DrugRoot.BrandName AS BrandName,
        Rx.DispQty AS RxQtyDispense,
        Rx.RemQty AS QtyRemain,
        Rx.SIG AS SIGFull,
        Rx.PatID AS PatientID,
        Rx.DocID,
        rx.DaysSupply as Days,
        Doc.Licence1 as CPSO,
        Doc.LastName,
        Doc.FirstName,
        DoctorFax.Phone as DoctorFax,
        DoctorPhone.Phone as DoctorPhone
    FROM
        Pharmacy.dbo.Rx 
    INNER JOIN 
        Pharmacy.dbo.Drg as DrugRoot ON Rx.DrgID = DrugRoot.ID
    INNER JOIN 
        Pharmacy.dbo.Doc ON Rx.DocID = Doc.ID
    LEFT JOIN
        Pharmacy.dbo.DocPhone as DoctorFax ON Doc.ID = DoctorFax.DocID AND DoctorFax.Type = '2'
    LEFT JOIN 
        Pharmacy.dbo.DocPhone as DoctorPhone ON Doc.ID = DoctorPhone.DocID AND DoctorPhone.Type = '1'
    WHERE
        Rx.FillDate > DATEADD(year, -1, GetDate()) AND (Rx.Status <> '2' OR Rx.Status <> '3' OR Rx.Status is null)
    AND
        ${where.join(' AND ')}
    ORDER BY
        RxDate Desc
        `, attrs)
        .then(handleQueryResolve)
    }

}